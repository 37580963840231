import React, { useState } from "react";
import MyCerts from "../Components/MyCerts";
import "../styles/Projects.css";
import { CertList } from "../List";

const Certs = () => {
  const [single, setSingle] = useState(false);
  const [item, setItem] = useState(1);

  return (
    <div className="projects">
      {!single ? (
        <>
          <h1>My Certificates</h1>
          <div className="projectList">
            {CertList.map((cert, index) => {
              return (
                <MyCerts
                  key={index}
                  id={index}
                  name={cert.name}
                  image={cert.image}
                  setItem={setItem}
                  setSingle={setSingle}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className="project">
          <h1>{CertList[item].name}</h1>
          <img
            onClick={() => setSingle(false)}
            src={CertList[item].image}
            alt=""
          ></img>
          <a href={CertList[item].link} target="blank">
            View Certificate Credentials
          </a>
        </div>
      )}
    </div>
  );
};

export default Certs;
