import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { School } from "@mui/icons-material";
import { Work } from "@mui/icons-material";

const Experience = () => {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="1991-1996"
          iconStyle={{ background: "#3e497a", color: "white" }}
          icon={<School />}
        >
          <h3 className="certical-timeline-element-title">
            Faculty of Mechanical Engineering, Nis
          </h3>
          <p>Bachelor's degree</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="1996 - 1998"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">Assistant teacher</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Faculty of Mechanical Engineering, Nis
          </h4>
          <p>
            Held practical classes to students on subject "Machine elements",
            while working in institute of Mechanical faculty. Helped my
            colleague to convert his PhD from FORTRAN to Visual Basic.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="1998 - 2000"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">
            Outsource Translator
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Kompjuter biblioteka, Cacak
          </h4>
          <p>
            I translated books from English to Serbian for a publishing company
            from Cacak. I was co-translator for "CorelDRAW 8", "Peter Norton's
            Windows 98" and "Peter Norton's guide to Visual Basic 6" - where I
            was a sole translator.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2001 - 2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">Entrepreneur</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Pin Servis, Leskovac
          </h4>
          <p>
            I started my own company for software and hardware repairing of
            mobile phones. That job gave me a lot of spare time to learn
            software development. I started with Visual Basic and made my first
            commercial software for customer-evidence by order from some similar
            company. Then I created administrative bookkeeping application for
            myself, which is still in use today. I started to code in JavaScript
            6 months ago.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022 - 2023"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">Freelancer</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Work from home, Leskovac
          </h4>
          <p>
            Started to work as a freelancer. Focused on building new projects
            and maintaining projects in use. I maintain apartment booking domain
            fina.iz.rs and all of its subdomains as my projects.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2023 - present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">Freelancer</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Work from home, Leskovac
          </h4>
          <p>
            Since start of 2023, developed full-stack PERN application with
            node.js backend support and POSTGRESQL database, for a company
            NETICO from Nis. Project included role-based user login, data-input,
            updating database, printing graphs and printing report in PDF.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2024 - present"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<Work />}
        >
          <h3 className="vertical-timeline-element-title">Teacher</h3>
          <h4 className="vertical-timeline-element-subtitle">
            IT Teacher, Leskovac
          </h4>
          <p>
            Started teaching in elementary school in near-by school
            as I believe youngsters are our future. I held IT classes
            for 5-th to 8-th graded class, where we studied python. 
            Projects included Scratch games for fifth grade, arrays and turtle
            graphics for sixth grade, pygame for seventh grade and jupyter
            notebooks for eight grade.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
