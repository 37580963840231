import React from "react";

const MyCerts = ({ name, image, id, setItem, setSingle }) => {
  return (
    <div className="projectItem">
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="bgImage"
        onClick={() => {
          setItem(id);
          setSingle((prev) => !prev);
        }}
      ></div>
      <h1>{name}</h1>
    </div>
  );
};

export default MyCerts;
