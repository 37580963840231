import img1 from "./images/maloprodaja.png";
import img2 from "./images/weather.jpg";
import img3 from "./images/zaplanje.jpg";
import img4 from "./images/pcelinjak.jpg";
import img5 from "./images/slagalica.jpg";
import img6 from "./images/blackjack.jpg";
import img7 from "./images/pozamanterija.jpg";
import img8 from "./images/apartman.jpg";
import img9 from "./images/blog.jpg";
import img10 from "./images/netico.jpg";
import img01 from "./images/img01.jpg";
import img02 from "./images/img02.jpg";
import img03 from "./images/img03.jpg";
import img04 from "./images/img04.jpg";
import img05 from "./images/img05.jpg";
import img06 from "./images/img06.jpg";
import img07 from "./images/img07.jpg";
import img08 from "./images/img08.jpg";
import img09 from "./images/img09.png";
import imgg1 from "./images/yamb.png"
import imgg2 from "./images/blackjack.png"
import imgg3 from "./images/skocko.png"
import imgg4 from "./images/broj.png"


export const List = [
  {
    name: "Fina-Apartman",
    image: img8,
    about:
      "Holiday apartment rental agency. Live at fina.iz.rs. Firebase is used to keep track of reservations and emailjs library used for owner email notification",
    link: "https://fina.iz.rs",
  },
  {
    name: "Analiza parcijalnih praznjenja",
    image: img10,
    about:
      "Full stack PERN application made for company NETICO. Front is made in React, backend in node.js and POSTGRE-SQL is database. Complex aplication where users with 4 different roles log-in, for data-input, data analysis, print reports and keep track of order state. Libs used: react-chart-js for drawing diagrams, react-to-print for printing report to pdf and some more... Project consists of 6 different modules with role-based-access",
  },
  {
    name: "Site Pozamanterija",
    image: img7,
    about:
      "Simple commercial site for laundry made in NEXTJS. Live at finapozamanterija.vercel.app",
    link: "https://finapozamanterija.vercel.app",
  },

  {
    name: "Book-keeping app",
    image: img1,
    about:
      "Pure Node app for simple bookkeeping (order, sell, nivelation and product inventory with product tracking from entering the store to selling. PostgreSQL database keeps data on my home server and app is served through local network on my linux machine, awailable to all users that pass JWT authentication. Sell order can be entered manually or obtained from local Tax administration site (we are on-line with sell monitoring in Serbia).",
  },

  {
    name: "Weather geo app",
    image: img2,
    about:
      "Geolocation weather app that makes API calls for geo-position of entered city, another API call to obtain weather information for that city and displays it on GoogleMap.",
    link: "https://geo-ip-weather.netlify.app",
  },
  {
    name: "BeeYard e-comerce app",
    image: img3,
    about:
      "E-commerce site for my Apiary. Added Map in footer to display apiary location. Live at zaplanjski-med.netlify.app",
    link: "https://zaplanjski-med.netlify.app",
  },
  {
    name: "Apiary management app",
    image: img4,
    about:
      "An Application every beekeeper shold have to keep record of his hives and their condition after reviews. Live at pcelinjak.netlify.app",
    link: "https://pcelinjak.netlify.app",
  },
  {
    name: "Game Slagalica",
    image: img5,
    about: "Simple game built in React for fun.",
    link: "https://slagalica.netlify.app",
  },
  {
    name: "Game Blackjack",
    image: img6,
    about:
      "First game I made using Vanilla JavaScript. Doesn't include all aspects of the game like splitting cards and doubling the bets. Made just for practise and fun.",
    link: "https://github.com/Nenadpin/Blackjack",
  },
  {
    name: "Blog for personal use",
    image: img9,
    about:
      "An Application for personal use. Just a place to burry ones thoughts. All posts are saved on firebase firestore, well protected",
    link: "https://nenadst.000webhostapp.com",
  },
];

export const CertList = [
  {
    name: "English Comprehension and Communication",
    image: img01,
    link: "https://www.testdome.com/certificates/ad5292a8f77242eeadb0a2ed27d3895d",
  },
  {
    name: "Attention to detail",
    image: img06,
    link: "https://www.testdome.com/certificates/e2f64cdd33e5466e872bebbcdfffb89e",
  },
  {
    name: "Version control",
    image: img02,
    link: "https://www.coursera.org/account/accomplishments/certificate/EVYBKQD6RPBL",
  },
  {
    name: "Introduction to FrontEnd Development",
    image: img03,
    link: "https://www.coursera.org/account/accomplishments/certificate/WDDE8U9YT7VK",
  },
  {
    name: "Programming in HTML5 with JavaScript and CSS3",
    image: img04,
    link: "https://www.coursera.org/account/accomplishments/certificate/V3V59ADXFABF",
  },
  {
    name: "React",
    image: img05,
    link: "https://www.testdome.com/certificates/ed4131be7a6d439d8db1920e3aef8cd3",
  },
  {
    name: "Backend essentials",
    image: img07,
    link: "https://www.testdome.com/certificates/c3e44b4db7f94d91a5b39bf085126ff3",
  },
  {
    name: "SQL",
    image: img08,
    link: "https://www.testdome.com/certificates/2d4489b44f664f1aaf28576a60fd564f",
  },
  {
    name: "PHP",
    image: img09,
    link: "https://www.testdome.com/certificates/9c733a87683f42bd94e22cdf4aab563c",
  },
  
];

export const GameList = [
  {
    name: "Yamb game",
    image: imgg1,
    link: "https://nenadpin.ddns.net/yamb.apk",
  },
  {
    name: "Blackjack game",
    image: imgg2,
    link: "https://nenadpin.ddns.net/blackjack.apk",
  },
  {
    name: "Slagalica - Skocko",
    image: imgg3,
    link: "https://nenadpin.ddns.net/skocko.apk",
  },
  {
    name: "Find a number - Moj broj",
    image: imgg4,
    link: "https://nenadpin.ddns.net/mojbroj.apk",
  },  
];

