import React, { useState } from "react";
import MyProject from "../Components/MyProject";
import "../styles/Projects.css";
import { List } from "../List";

const Projects = () => {
  const [single, setSingle] = useState(false);
  const [item, setItem] = useState(1);

  return (
    <div className="projects">
      {!single ? (
        <>
          <h1>My Personal Projects</h1>
          <div className="projectList">
            {List.map((project, index) => {
              return (
                <MyProject
                  key={index}
                  id={index}
                  name={project.name}
                  image={project.image}
                  setItem={setItem}
                  setSingle={setSingle}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className="project">
          <h1>{List[item].name}</h1>
          <img
            onClick={() => setSingle(false)}
            src={List[item].image}
            alt=""
          ></img>
          {List[item].link ? (
            <a href={List[item].link} target="blank">
              <p>
                <b>About app: </b>
                {List[item].about}
              </p>
            </a>
          ) : (
            <p>
              <b>About app: </b>
              {List[item].about}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Projects;
