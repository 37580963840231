import React from "react";
import Phone from "@mui/icons-material/Phone";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Email from "@mui/icons-material/Email";
import GitHub from "@mui/icons-material/GitHub";

const Social = () => {
  return (
    <div>
      <a href="tel:+381641227779">
        <Phone titleAccess="Phone" />
      </a>
      <a href="https://linkedin.com/in/nenadpin" target="blank">
        <LinkedIn titleAccess="LinkedIn" />
      </a>
      <a href="https://github.com/nenadpin" target="blank">
        <GitHub titleAccess="GitHub" />
      </a>
      <a href="mailto:nenadpin@yahoo.com">
        <Email titleAccess="e-mail" />
      </a>
    </div>
  );
};

export default Social;
