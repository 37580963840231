import "../styles/Projects.css";
import MyGame from "../Components/MyGames";
import { GameList } from "../List";

const Games = () => {
  return (
    <div className="projects">
        <>
          <h1>Android games</h1>
          <div className="projectList">
            {GameList.map((project, index) => {
              return (
                <MyGame
                  key={index}
                  id={index}
                  name={project.name}
                  image={project.image}
                  loc={project.link}
                />
              );
            })}
          </div>
        </>
    </div>
  );
};

export default Games;
