import "./App.css";
import Home from "./Pages/Home";
import Experience from "./Pages/Experience";
import Projects from "./Pages/Projects";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { useState } from "react";
import Certs from "./Pages/Certs";
import Games from "./Pages/Games"


function App() {
  const [rute, setRute] = useState(0);
  return (
    <div className="App">
      <Navbar setRute={setRute} />
      {rute === 1 ? (
        <Projects />
      ) : rute === 2 ? (
        <Experience />
      ) : rute === 3 ? (
        <Certs />
      ) : 
        rute === 4 ? (
        <Games />
      ):
        <Home />
      }
      <Footer />
    </div>
  );
}

export default App;
