import React from "react";
import Social from "../Components/Social";
import "../styles/Home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="about">
        <h2>Nenad Stamenkovic</h2>
        <div className="prompt">
          <p>Junior full-stack developer, allways learning new skills</p>
          <Social />
        </div>
      </div>
      <div className="skills">
        <h1>Skills:</h1>
        <ol className="list">
          <li className="item">JavaScript</li>
          <li className="item">React.js</li>
          <li className="item">HTML</li>
          <li className="item">CSS</li>
          <li className="item">GitHub</li>
          <li className="item">NodeJS</li>
          <li className="item">PostgreSQL</li>
        </ol>
      </div>
    </div>
  );
};

export default Home;
