import React from "react";

const MyGame = ({ name, image, loc }) => {
  return (
    <div className="projectItem">
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="bgImage"
        onClick={()=>window.location=loc}
      ></div>
      <h1>{name}</h1>
    </div>
  );
};

export default MyGame;
