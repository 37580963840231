import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import Reorder from "@mui/icons-material/Reorder";
import { Home } from "@mui/icons-material";
import { Work } from "@mui/icons-material";
import { List } from "@mui/icons-material";

const Navbar = ({ setRute }) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setExpand(false);
  }, []);

  return (
    <div className="navbar" id={expand ? "open" : "close"}>
      <div className="toggleButton">
        <button onClick={() => setExpand((prev) => !prev)}>
          <Reorder />
        </button>
      </div>
      <div className="links">
        <button
          onClick={() => {
            setRute(0);
            setExpand(false);
          }}
        >
          {expand ? <Home /> : null}
          HOME
        </button>
        <button
          onClick={() => {
            setRute(2);
            setExpand(false);
          }}
        >
          {expand ? <Work /> : null}
          EXPERIENCE
        </button>
        <button
          onClick={() => {
            setRute(1);
            setExpand(false);
          }}
        >
          {expand ? <List /> : null}
          PROJECTS
        </button>
        <button
          onClick={() => {
            setRute(3);
            setExpand(false);
          }}
        >
          {expand ? <List /> : null}
          CERTIFICATES
        </button>
        <button
          onClick={() => {
            setRute(4);
            setExpand(false);
          }}
        >
          {expand ? <List /> : null}
          GAMES
        </button>
      </div>
    </div>
  );
};

export default Navbar;
