import React from "react";
import Social from "./Social";
import "../styles/Footer.css";
import train from "../styles/train.jpg";

function Footer() {
  return (
    <div className="footer">
      <div className="train">
        <img src={train} alt=""></img>
      </div>
      <div className="social">
        <div className="socialMedia">
          <Social />
        </div>
        <p> &copy; 2022 Nenad Stamenkovic</p>
      </div>
    </div>
  );
}

export default Footer;
